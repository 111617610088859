import { useState } from 'react'

import { Form, Grid, Button } from '@enterprise-ui/canvas-ui-react'

import { DEFAULT_SEARCH_VALUES } from '.'

export function AuditTableFilters({ onSearch }) {
  const [values, setValues] = useState(DEFAULT_SEARCH_VALUES)

  const onSubmit = (e) => {
    e.preventDefault()
    onSearch(values)
  }

  const resetFormValues = () => {
    setValues(DEFAULT_SEARCH_VALUES)
    onSearch(DEFAULT_SEARCH_VALUES)
  }

  return (
    <form onSubmit={onSubmit}>
      <Grid.Container spacing="dense">
        <Form.Field
          value={values.load_id || ''}
          placeholder="Load ID"
          data-testid="audit-loadid"
          className="hc-mr-xs"
          onChange={(e) => setValues({ ...values, load_id: e.target.value })}
        />
        <Form.Field
          value={values.user_id || ''}
          placeholder="User ID"
          data-testid="audit-userid"
          className="hc-mr-xs"
          onChange={(e) => setValues({ ...values, user_id: e.target.value })}
        />
        <Form.Field
          value={values.scac || ''}
          placeholder="SCAC"
          data-testid="audit-scac"
          className="hc-mr-xs"
          onChange={(e) => setValues({ ...values, scac: e.target.value })}
        />
        <Button
          type="submit"
          className="hc-mr-xs"
          data-testid="audit-search-btn"
        >
          Search
        </Button>
        <Button onClick={resetFormValues}>Clear</Button>
      </Grid.Container>
    </form>
  )
}
