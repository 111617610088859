import { Grid, Card, Heading } from '@enterprise-ui/canvas-ui-react'
import ValidateEdi from '../shared/ValidateEdi'

export default function Validate214() {
  return (
    <Card className="hc-pa-md hc-ov-scroll edi-container">
      <Grid.Container direction="column" className="hc-pa-md" noWrap={true}>
        <Grid.Item>
          <Heading data-testid="validate-214-heading" size={3}>
            Validate EDI 214
          </Heading>
        </Grid.Item>
        <Grid.Item>
          <ValidateEdi ediType={214} />
        </Grid.Item>
      </Grid.Container>
    </Card>
  )
}
