import axios from 'axios'

import apiConfig from '../config/apiConfig'

/**
 * Downloads 204 EDI
 * @param {*} params
 * @param {*} accessToken
 */
export async function generate204EDI(params, accessToken) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiConfig.urls.carrierOnboarding}/generate_204`, {
        params: params,
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        const filename = response.headers['content-disposition']
          .split('filename=')[1]
          .replace(/['"]+/g, '')
        const elem = document.createElement('a')
        elem.href = window.URL.createObjectURL(new Blob([response.data]))
        elem.download = filename
        elem.click()
        resolve()
      })
      .catch((error) => {
        reject(error)
      })
  })
}
