import { useState } from 'react'

import {
  Form,
  Button,
  ToastProvider,
  Grid,
  Card,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  PaperAirplaneIcon,
  FileIcon,
} from '@enterprise-ui/icons'

import Separator from '../Separator'
import useUser from '../../../hooks/useUser'
import { validateEdiContent } from '../../../services/validateEdi'
import ErrorTable from '../ErrorTable'
import { formatEdiErrorData } from '../../../utils'

const VALID_EDI_FILE_EXTENSIONS = ['.edi', '.txt', '.x12']
const MAX_FILE_LENGTH = 200000

const COLUMN_DEFS = [
  {
    field: 'uniqueTrackingNumber',
    headerName: 'Tracking Number',
    width: 100,
    resizable: true,
    isRowHeader: true,
  },
  {
    field: 'errorType',
    headerName: 'Error Type',
    width: 140,
    resizable: true,
  },
  {
    field: 'errorDescription',
    headerName: 'Error Description',
    resizable: true,
  },
]

/**
 * Component for Validating EDI
 * Takes ediType as props (990|214)
 * @returns JSX
 */
export default function ValidateEdi({ ediType }) {
  const extensionString = VALID_EDI_FILE_EXTENSIONS.join(', ')

  const { accessToken } = useUser()
  const makeToast = ToastProvider.useToaster()

  const [isLoading, setLoading] = useState(false)
  const [ediData, setEdiData] = useState('')
  const [fileName, setFileName] = useState('')
  const [errors, setErrors] = useState(null)

  const handleFileSelect = (event) => {
    const file = event.target.files[0]

    if (!file) return

    const fileExtensionWithDot = '.' + file.name.split('.').pop()

    if (VALID_EDI_FILE_EXTENSIONS.includes(fileExtensionWithDot)) {
      const fileReader = new FileReader()
      fileReader.onloadend = (e) => {
        if (e.target.result.length < MAX_FILE_LENGTH) {
          setFileName(file.name)
          setEdiData(e.target.result)
        } else {
          makeToast({
            type: 'error',
            heading: 'File length exceed',
            message: `File length should not exceed limit of ${MAX_FILE_LENGTH} characters`,
          })
        }
      }
      fileReader.readAsText(file)
    } else {
      makeToast({
        type: 'error',
        heading: 'Invalid file type',
        message: `Please select a ${extensionString} file`,
      })
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    setLoading(true)
    setErrors(null)

    const params = {
      edi_type: 'EDI_' + ediType,
    }
    validateEdiContent(ediData, params, accessToken)
      .then(() => {
        makeToast({
          type: 'success',
          heading: `EDI ${ediType} parsed successfully`,
          message: 'No error found in validation',
        })
      })
      .catch((error) => {
        if (error.response.status === 400) {
          setErrors(formatEdiErrorData(error.response.data))
          makeToast({
            type: 'error',
            heading: 'Error',
            message: `Error found in EDI ${ediType} validation`,
          })
        } else {
          makeToast({
            type: 'error',
            heading: 'Error',
            message: `Failed to parse EDI ${ediType}`,
          })
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Grid.Container justify="center">
      <Grid.Item xs={5}>
        <Card className="hc-pa-3x">
          <Form onSubmit={handleSubmit}>
            <Form.Field
              data-testid="edi-input"
              type="textarea"
              label={`EDI ${ediType}`}
              value={ediData}
              onChange={(e) => setEdiData(e.target.value)}
              placeholder={`Paste the EDI ${ediType} data here`}
            />
            <Separator />
            <Form.Field
              dropText="Select File"
              onUpdate={handleFileSelect}
              type="drop-area"
              fullwidth
              clickOnly
              accept={extensionString}
              instructionText={`${extensionString} extensions are allowed`}
            />
            {fileName && (
              <Grid.Container align="center" className="hc-mt-sm hc-ml-none">
                <EnterpriseIcon icon={FileIcon} className="hc-mr-min" />
                {fileName}
              </Grid.Container>
            )}
            <Button
              type="submit"
              isLoading={isLoading}
              className="hc-mt-xl"
              fullWidth
              data-testid="edi-validate-btn"
              disabled={!ediData}
            >
              <EnterpriseIcon
                icon={PaperAirplaneIcon}
                size="sm"
                className="hc-mr-xs"
              />
              Validate
            </Button>
          </Form>
        </Card>
      </Grid.Item>
      <Grid.Item xs={12} className="hc-mt-lg">
        {errors && (
          <ErrorTable
            heading="EDI Error Details"
            columnDefs={COLUMN_DEFS}
            rowData={errors}
          />
        )}
      </Grid.Item>
    </Grid.Container>
  )
}
