import { Card, Heading, Table } from '@enterprise-ui/canvas-ui-react'

/**
 * Error table for EDI validation
 * @returns JSX
 */
export default function ErrorTable({
  heading,
  headingSize = 4,
  columnDefs = [],
  rowData = [],
}) {
  return (
    <Card className="hc-pa-lg">
      <Heading size={headingSize} className="hc-mb-md">
        {heading}
      </Heading>
      <Table
        data={{
          defaultColDef: { sortable: true },
          columnDefs: columnDefs,
          rowData: rowData,
        }}
      ></Table>
    </Card>
  )
}
