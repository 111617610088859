import axios from 'axios'

import apiConfig from '../config/apiConfig'

/**
 * Validate EDI (204|990)
 * @param {*} ediContent
 * @param {*} params
 * @param {*} accessToken
 */
export function validateEdiContent(ediContent, params, accessToken) {
  const config = {
    method: 'post',
    url: `${apiConfig.urls.carrierOnboarding}/process_message`,
    headers: {
      'Content-Type': 'text/plain',
      Authorization: `Bearer ${accessToken}`,
    },
    params: { ...params },
    data: ediContent,
  }
  return axios(config)
}
