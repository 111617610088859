import { useState, useCallback } from 'react'

import {
  Button,
  ButtonGroup,
  Grid,
  Layout,
} from '@enterprise-ui/canvas-ui-react'

import '@enterprise-ui/canvas-ui-css'
import '@dlm/common/dist/esm/css/styles.css'

import AuditData from '../components/AuditData'
import Generate204 from '../components/Generate204'
import Validate214 from '../components/Validate214'
import Validate990 from '../components/Validate990'

import '../stylesheets/customStyles.scss'
import '../stylesheets/style.scss'

const VIEWS = {
  GENERATE_204: '1',
  VALIDATE_990: '2',
  VALIDATE_214: '3',
  AUDIT_DATA: '4',
}

export default function CarrierOnboarding() {
  const [selectedView, setSelectedView] = useState(VIEWS.GENERATE_204)

  const getSelectedView = useCallback(() => {
    switch (selectedView) {
      case VIEWS.GENERATE_204:
        return <Generate204 />
      case VIEWS.VALIDATE_990:
        return <Validate990 />
      case VIEWS.VALIDATE_214:
        return <Validate214 />
      case VIEWS.AUDIT_DATA:
        return <AuditData />
      default:
        return null
    }
  }, [selectedView])

  return (
    <Layout.Body includeRail>
      <Grid.Container justify="space-between" style={{ marginBottom: 10 }}>
        <Grid.Item>
          <ButtonGroup>
            <Button
              onClick={() => setSelectedView(VIEWS.GENERATE_204)}
              type={
                selectedView === VIEWS.GENERATE_204 ? 'primary' : 'secondary'
              }
            >
              Generate 204
            </Button>
            <Button
              onClick={() => setSelectedView(VIEWS.VALIDATE_990)}
              type={
                selectedView === VIEWS.VALIDATE_990 ? 'primary' : 'secondary'
              }
            >
              Validate EDI 990
            </Button>
            <Button
              onClick={() => setSelectedView(VIEWS.VALIDATE_214)}
              type={
                selectedView === VIEWS.VALIDATE_214 ? 'primary' : 'secondary'
              }
            >
              Validate EDI 214
            </Button>
            <Button
              onClick={() => setSelectedView(VIEWS.AUDIT_DATA)}
              type={selectedView === VIEWS.AUDIT_DATA ? 'primary' : 'secondary'}
            >
              Audit Data
            </Button>
          </ButtonGroup>
        </Grid.Item>
      </Grid.Container>
      <Grid.Container>
        <Grid.Item xs={12}>{getSelectedView()}</Grid.Item>
      </Grid.Container>
    </Layout.Body>
  )
}
