import CarrierOnboarding from '../views/CarrierOnboarding'

const routes = [
  {
    path: '/carrieronboarding',
    component: <CarrierOnboarding />,
    hideInEnvironment: [],
  },
]

const sideNavItems = {
  carrieronboarding: {
    label: 'Carrier EDI Onboarding',
    hideInEnvironment: ['prod', 'stg'],
    path: '/carrieronboarding',
  },
}

const navigationConfig = {
  routes,
  sideNavItems,
}

export default navigationConfig
