import { useState, useEffect } from 'react'

import { ProgressOverlay, NovaTable } from '@dlm/common'
import {
  Grid,
  Tooltip,
  Button,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { RefreshIcon, ExportIcon } from '@enterprise-ui/icons'

import { getAuditData } from '../../services/auditData'
import useUser from '../../hooks/useUser'
import { downloadAuditTableData, mapAuditTableKeys } from '../../utils'
import { AuditTableFilters } from './AuditTableFilters'

const ROWS_PER_PAGE = 50

export const DEFAULT_SEARCH_VALUES = {
  load_id: null,
  user_id: null,
  scac: null,
}

const TABLE_COLUMNS = [
  {
    field: 'loadId',
    headerName: 'Load Id',
    width: 120,
    isRowHeader: true,
    resizable: true,
    sortable: true,
  },
  {
    field: 'userId',
    headerName: 'User ID',
    width: 120,
    sortable: true,
    resizable: true,
  },
  {
    field: 'scac',
    headerName: 'SCAC',
    width: 100,
    sortable: true,
    resizable: true,
  },
  {
    field: 'modeType',
    headerName: 'Mode Type',
    width: 130,
    resizable: true,
    sortable: true,
  },
  {
    field: 'ediType',
    headerName: 'EDI Type',
    width: 120,
    resizable: true,
    sortable: true,
  },
  {
    field: 'isaNumber',
    headerName: 'ISA',
    width: 120,
    resizable: true,
    sortable: true,
  },
  {
    field: 'proNumber',
    headerName: 'Pro Number',
    width: 130,
    resizable: true,
    sortable: true,
  },
  {
    field: 'auditStatus',
    headerName: 'Status',
    width: 140,
    resizable: true,
    sortable: true,
  },
  {
    field: 'location',
    headerName: 'Location',
    width: 250,
    resizable: true,
    sortable: true,
  },
  {
    field: 'activity',
    headerName: 'Activity',
    width: 230,
    resizable: true,
    sortable: true,
  },
  {
    field: 'createdTimestamp',
    headerName: 'Created Date',
    width: 150,
    resizable: true,
    sortable: true,
    defaultSort: 'dsc',
  },
  {
    field: 'source',
    headerName: 'Source',
    width: 150,
    resizable: true,
    sortable: true,
  },
  {
    field: 'inputData',
    headerName: 'Content',
    resizable: true,
    sortable: true,
  },
]

export default function AuditData() {
  const { accessToken } = useUser()
  const makeToast = ToastProvider.useToaster()

  const [filters, setFilters] = useState({
    page: 1,
    per_page: ROWS_PER_PAGE,
    sort_by: 'createdTimestamp',
    sort_order: 'desc',
  })
  const [tableData, setTableData] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [totalRecords, setTotalRecords] = useState(0)

  useEffect(() => {
    setLoading(true)
    getAuditData(filters, accessToken)
      .then((res) => {
        const formattedData = mapAuditTableKeys(res.data?.audit_data_list)
        setTableData(formattedData)
        setTotalRecords(res.data?.total_record)
      })
      .catch(() => {
        makeToast({
          type: 'error',
          heading: 'Error',
          message: 'Could not fetch audit data. Please try again later.',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [filters, accessToken, makeToast])

  const onRefresh = () => {
    setFilters((f) => {
      return { ...f }
    })
  }

  const onPaginationChange = (pageNum, pageSize) => {
    setFilters((f) => {
      return { ...f, page: pageNum, per_page: pageSize }
    })
  }

  const onSortChange = (direction, field) => {
    direction = direction === 'dsc' ? 'desc' : direction
    setFilters((f) => {
      return { ...f, sort_order: direction, sort_by: field }
    })
  }

  const onSearch = (searchData) => {
    setFilters((f) => {
      return { ...f, ...searchData }
    })
  }

  const onExport = () => {
    downloadAuditTableData(TABLE_COLUMNS, tableData)
  }

  return (
    <>
      <ProgressOverlay inProgress={isLoading} />
      <NovaTable
        name="Audit Data"
        showHeader
        subHeader={<AuditTableFilters onSearch={onSearch} />}
        columnDefs={TABLE_COLUMNS}
        rowData={tableData}
        rowCount={totalRecords}
        pageNum={filters.page}
        pageSize={filters.per_page}
        onPaginationChange={onPaginationChange}
        onSortChange={onSortChange}
        tableActions={
          <Grid.Container
            align="center"
            justify="flex-end"
            spacing="dense"
            noWrap
          >
            <Grid.Item>
              <Tooltip content="Refresh Audit" location="bottom">
                <Button
                  iconOnly
                  aria-label="Refresh Audit"
                  className="table-action-button"
                  data-testid="audit-refresh-btn"
                  onClick={onRefresh}
                >
                  <EnterpriseIcon icon={RefreshIcon} />
                </Button>
              </Tooltip>
            </Grid.Item>
            <Grid.Item>
              <Tooltip content="Export CSV" location="bottom">
                <Button
                  iconOnly
                  aria-label="Export CSV"
                  className="table-action-button"
                  data-testid="audit-export-btn"
                  onClick={onExport}
                >
                  <EnterpriseIcon icon={ExportIcon} />
                </Button>
              </Tooltip>
            </Grid.Item>
          </Grid.Container>
        }
      />
    </>
  )
}
