/*

👋 “Hey” from the Praxis team!

'apiConfig' is one pattern that can be used to set environment-based values at build time.
This happens by creating an environment variable REACT_APP_ENV, and then matching corresponding
keys to each environment in the 'envConfigs' object below. To learn more about how to set
REACT_APP_ENV, view the README generated with this app.


For an alternative pattern - using TAP configuration to set environment-based values at cluster initialization - see:
@praxis/component-runtime-env - https://praxis.prod.target.com/components/runtime-env

*/

import merge from 'lodash/merge'

// configurations shared between most or all environments can be stored in commonConfig
const commonConfig = {
  auth: {
    authorizationUrl: window.location.hostname.includes('partnersonline.com')
      ? 'https://oauth.iam.perf.partnersonline.com/auth/oauth/v2/tgt/authorize/nla/1'
      : 'https://oauth.iam.perf.target.com/auth/oauth/v2/authorize',
    logoutUrl: window.location.hostname.includes('partnersonline.com')
      ? 'https://oauth.iam.perf.partnersonline.com/login/responses/logoff.html'
      : 'https://logonservices.iam.perf.target.com/login/responses/logoff.html',
    requiredPermissions: {
      user: ['APP-OAUTH2-MER-COP-USER'],
      carrier: ['APP-OAUTH2-TRANS-COS-STG-USER'],
      admin: ['APP-OAUTH2-TRANS-COS-STG-ADMN'],
    },
  },
}

const envConfigs = {
  // dev is the default development environment set by .env.development and the default pipeline in .vela.yml
  dev: {
    auth: {
      clientId: 'dlm_npe_im',
    },
    urls: {
      carrierOnboarding:
        'https://stage-api.target.com/carrier_edi_onboarding_service/v1',
    },
  },
  /*
  // additional environments can be added:
  stg: {

  },
  */
  prod: {
    auth: {
      // keys in envConfigs will overwrite keys from commonConfig
      authorizationUrl: window.location.hostname.includes('partnersonline.com')
        ? 'https://oauth.iam.partnersonline.com/auth/oauth/v2/tgt/authorize/nla/1'
        : 'https://oauth.iam.target.com/auth/oauth/v2/authorize',
      logoutUrl: window.location.hostname.includes('partnersonline.com')
        ? 'https://oauth.iam.partnersonline.com/login/responses/logoff.html'
        : 'https://logonservices.iam.target.com/login/responses/logoff.html',
      clientId: 'dlm_prod_im',
    },
    urls: {
      carrierOnboarding:
        'https://api.target.com/carrier_edi_onboarding_service/v1',
    },
  },
}

const appEnv = process.env.REACT_APP_ENV
const config = envConfigs[appEnv]

// commonConfig and the config for the matching REACT_APP_ENV are combined.
// Values in the environment-specific config will overwrite commonConfig keys
// if they share the same name.
const apiConfig = merge(commonConfig, config)

export default apiConfig
