import axios from 'axios'

import apiConfig from '../config/apiConfig'

/**
 * Get filter based audit data
 * @param {*} params
 * @param {*} accessToken
 */
export function getAuditData(params, accessToken) {
  const config = {
    method: 'get',
    url: `${apiConfig.urls.carrierOnboarding}/fetch_audit_data`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: { ...params },
  }
  return axios(config)
}

/**
 * Get audit error data
 * @param {*} params
 * @param {*} accessToken
 */
export function getAuditErrorData(params, accessToken) {
  return axios.get(`${apiConfig.urls.carrierOnboarding}/audit_error_data`, {
    params: params,
    headers: { Authorization: `Bearer ${accessToken}` },
  })
}
